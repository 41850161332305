<template>
  <div>
    <base-template :show-head="false" v-show="!isCupon">
      <!-- 借款额度 -->
      <loan-money ref="loanMoney" :pay-type="$t('baseInfo.shouldPay')" :order-id="orderId" :data-form="borrowingInfo"
        @repay_num="handlerepay_num" :cuponmoney="cuponDto ? (Number(cuponDto.TUZLhbtv)) : 0" />
      <div class="bottom-area">
        <!-- 查看支付码 -->
        <div class="checkPaycode" v-if="repaymentCode" @click="checkRepaymentCode">
          <div class="left">
            <img src="@/assets/images/stayPay/tips-icon.png" alt="">
            <p>{{ $t('stayPay.repaymentCode') }}</p>
          </div>
          <div class="right">
            <p>{{ $t('stayPay.checkRepaymentCode') }}</p>
            <img src="@/assets/images/stayPay/blue-next-icon.png" alt="">
          </div>
        </div>
        <div class="pay-price">
          <ul class="list-box">
            <li :class="{ 'is-active': isAllPay }" class="item" @click="handle_num('1'); isAllPay = true">
              <span class="title">{{ $t('userCenter.allPay') }}</span>
              <span class="cont">PHP {{ Math.ceil(infull_amount - (cuponDto ? cuponDto.TUZLhbtv : 0)) | toThousandFilter
              }}
              </span>
            </li>
            <li v-if="showExtension" :class="{ 'is-active': !isAllPay }" class="item"
              @click="handle_num('2'); isAllPay = false">
              <span class="title">{{ $t('userCenter.extensionpay') }}</span>
              <span class="cont">PHP {{ Math.ceil(exhibition_amount - (cuponDto ? cuponDto.TUZLhbtv : 0)) |
                toThousandFilter }}
              </span>
            </li>
          </ul>
        </div>
        <div class="coupon-box" @click="isCupon = true" v-if="dataList.length > 0 && showExtension">
          <div class="coupon-left">
            <img class="left-icon" src="@/assets/images/coupon/coupon-icon.png" alt="">
            <div v-if="cuponDto">
              <p class="coupon-name">{{ cuponDto.title }}</p>
              <p class="coupon-time">{{ $t('cupones.youxiaoqi') }}{{ cuponDto.IjjAhMsb }}</p>
            </div>
            <div v-else>
              <p class="coupon-name">{{ $t('cupones.weixuanzeyouhuiquan') }}</p>
            </div>
          </div>
          <div class="coupon-right">
            <span v-if="cuponDto">-{{ $t('public.unit1') }}{{ cuponDto.TUZLhbtv }}</span>
            <img class="right-icon" src="@/assets/images/coupon/coupon-right.png" alt="">
          </div>

        </div>
        <div class="friend-tips"> {{ isAllPay ? prompt_msg : prompt_msg2 }} </div>
        <submit-btn :sub-text="click_btn" submit-flag @submit="payCodeCreat" />
        <div class="msgs">
          <img src="@/assets/images/public/notice-icon.png">
          <!-- <marquee scrollamount="6" class="marquee" behavior="scroll"  >{{ notification_msg }}</marquee> -->
          <van-notice-bar class="marquee" :text="notification_msg"></van-notice-bar>
        </div>
      </div>
      <div class="reloan-enter" @click="toReloan">
        <img src="@/assets/images/stayPay/withdraw-gift.png" alt="">
      </div>
    </base-template>
    <cupon ref="cuponEl" v-show="isCupon" @headerBack="headerBack" @change="handleCupon" @interface="handleInterface"
      :type="1">
    </cupon>

    <van-popup v-model="popData.show" position="center" class="coupon-popup">
      <div class="coupon-container">
        <div class="top">
          <p class="popup-title">{{ popData.title }}</p>
          <p class="msg">{{ popData.con_ilyashi }}</p>

          <div class="coupon-content">
            <p class="coupon-amount-title"> {{ $t('cupones.couponDiscount') }}(peso)</p>
            <p class="coupon-amount">{{ getmoney(popData.ticket_amount) }}</p>
            <p class="expire-time">{{ $t('cupones.youxiaoqi') }}{{ popData.expire_date }}</p>
          </div>

          <p class="check" @click=checkCoupon>{{ $t('cupones.checkCoupon') }}>></p>
        </div>
        <div class="bottom">
          <p class="use-btn" @click="popData.show = false">{{ popData.btn_title }}</p>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getmoney } from '@/utils'
import LoanMoney from './components/loanMoney.vue'
import cupon from './cupon.vue'
import { getStayPayList, payCodeCreat, payCodePoint } from '@/api/axios'

export default {
  components: { LoanMoney, cupon },
  data() {
    return {
      isCupon: false,// 是否展示优惠券

      popData: {
        btn_title: "btn",
        con_ilyashi: "ssssssssssssssss",
        expire_date: "20-22-22",
        op_ticket_type: 1,
        show: false,
        ticket_amount: 1000,
        ticket_type: 1,
        title: "titleText"
      },//运营后台送券信息
      isAllPay: true,
      showExtension: false,//是否展示展期按钮
      orderId: '',
      notification_msg: '', // 滚动文案
      infull_amount: '', // 全额还款金额
      exhibition_amount: '', // 展期还款金额
      click_btn: '',
      prompt_msg: '',
      prompt_msg2: '',
      payout_type: '', // 卡类型 1 银行卡 2 电子钱包 3 汇款店
      borrowingInfo: {
        borrowingPrice: '',
        borrowingDate: '',
        loanTerm: '',
        overdueDay: '', // 逾期天数
        repayTime: '', // 应还时间
        pay_period: '',
        postpone_repay_time: ''
      },
      paypoint: {
        repayment_num: 0,	//还款详情点击次数
        money_num: 0,	//应还款金额点击次数
        extension_num: 0,	//展期应还款金额点击次数
        now_num: 0,	//立即还款点击次数
      },
      cuponDto: null,
      dataList: [],
      repaymentCode: "",//还款码

    }
  },

  watch: {
    isAllPay: {
      handler(val) {
        if (val) {
          this.$refs['loanMoney'].dataForm.borrowingPrice = this.infull_amount
        } else {
          this.$refs['loanMoney'].dataForm.borrowingPrice = this.exhibition_amount
        }
      }
    },
    isCupon: {
      handler(val) {
        if (val) {
          this.$H5Object.isShowBottomTabinter(false)
          this.$H5Object.changeStatusBarBgColor('#F4F5FC')
        } else {
          this.$H5Object.isShowBottomTabinter(true)
          this.$H5Object.changeStatusBarBgColor('#005FE9')
        }
      }
    },
  },
  created() {
    this.getStayPayList()
    this.$H5Object.changeStatusBarBgColor('#005FE9')
    this.$H5Object.changeStatusIsDarkFont(false)
    this.$H5Object.isShowBottomTabinter(true)
    this.$H5Object.canReloadHtml(true)
    window.refreshPages = this.handlerRefreshPages
    window.eaH5PageBack = this.goback
  },
  mounted() {

  },
  methods: {
    headerBack() {
      this.isCupon = false
    },
    // 选择的优惠券
    handleCupon(val, item) {
      this.cuponDto = item || null
      console.log(val, item, 'val,item');
    },
    // 收否有优惠券数据
    handleInterface(dataList) {
      this.dataList = dataList
    },
    // 刷新页面
    handlerRefreshPages() {
      this.getStayPayList()
    },
    goback() {
      this.$H5Object.cancleView()
    },
    handlerepay_num() {
      this.paypoint.repayment_num += 1
    },
    handle_num(type) {
      // 应还款
      if (type == 1) {
        this.paypoint.money_num += 1
      }
      // 展期
      if (type == 2) {
        this.paypoint.extension_num += 1
      }
    },
    // 查看个人中心优惠券
    checkCoupon() {
      this.$router.push({
        name: 'Cupones',
        query: {
          from: "stayPay"
        }
      })
      this.$H5Object.isShowBottomTabinter(false)
    },

    checkRepaymentCode() {
      this.$router.push({
        path: '/paycenterPage',
        query: {
          url: decodeURIComponent(this.repaymentCode)
        }
      })
      this.$H5Object.isShowBottomTabinter(false)
    },

    async getStayPayList() {
      let dto = {
        // UkucinjaKwakulekelesha:this.isAllPay?1:2
        need_home_pop_data: 1
      }
      const { data } = await getStayPayList(dto) || {}
      console.log(JSON.stringify(data) + '----')
      let backState = window.sessionStorage.getItem('backState') || false
      let isPaytype = data?.bill_data?.UkucinjaKwakulekelesha || ''
      this.popData = data?.pop_data //运营后台送券
      if ((data?.Ukufumya_icilefwaikwa + '') === '1' && isPaytype && !backState) {
        localStorage.setItem('payType', isPaytype)
        this.$router.push({ path: '/repayment' })
      }
      this.payDetailData = { ...(data?.bill_list || {}), ...(data?.pay_bank || {}) }
      this.borrowingInfo.borrowingPrice = data?.Palikuli || '0'
      this.borrowingInfo.overdueDay = data?.Ukucinja_kwakulekelesha || ''
      this.borrowingInfo.repayTime = data?.UkucinjaKwaku || '' // 全额还款日期
      this.borrowingInfo.postpone_repay_time = data?.zhanqi_UkucinjaKwaku || '' // 延期还款日期
      this.borrowingInfo.loanTerm = data?.bill_data?.Loan_term || '' // 借款周期
      this.borrowingInfo.pay_period = data?.loan_nums || '1' // 当前期
      this.click_btn = data?.ButtonCopy
      this.orderId = data?.IfyakushitaID || ''
      this.payout_type = data?.payout_type || ''
      this.notification_msg = data?.Inyimboo
      this.infull_amount = data?.Palikuli || 0
      this.exhibition_amount = data?.Ukufuma_fye || 0
      this.prompt_msg = data?.Ukufislwe || ''
      this.prompt_msg2 = data?.Ukufislwe2 || ''
      this.repaymentCode = data?.payment_url
      this.showExtension = data?.hide_zhanqi == 1
    },

    async payCodeCreat() {

      this.paypoint.now_num += 1
      try {
        const params = {
          IfyakushitaID: this.orderId, // 订单id
          UkucinjaKwakulekelesha: this.isAllPay ? '1' : '2', // 1：全额还款 2：展期还款
          MXtFfHQd: 0,//使用购买抵金券的id
          xfCRXCBN: this.cuponDto ? this.cuponDto.id : 0,//使用赠送抵金券的id
        }
        const res = await payCodeCreat(params)
        localStorage.setItem('payType', params.UkucinjaKwakulekelesha)
        if (res.inyimbo == 200) {
          await payCodePoint(this.paypoint)
          this.$router.push({
            path: '/paycenterPage',
            query: {
              url: decodeURIComponent(res.data.payment_url)
            }
          })
          this.$H5Object.isShowBottomTabinter(false)
        }
        //this.$router.push({ path: '/repayment' })
      } catch (err) {
        console.log(err)
      }
    },

    goChoosePayType(params) {
      if (this.isAndirodData) {
        this.$H5Object.repayment(params)
      } else {
        this.$router.push({
          path: '/choosePayType',
          query: { queryData: params }
        })
      }
    },
    toReloan() {
      // 查看复贷额度
      this.$router.push({
        path: '/ReloanAmount',

      })
    }

  }
}
</script>
<style lang="scss" scoped>
:deep(.is-content) {
  padding: 0;
}

.bottom-area {
  padding: 100px 40px 0;
  overflow: hidden;

  .checkPaycode {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F4F5FC;
    border-radius: 32px;
    padding: 40px 32px;

    .left {
      display: flex;
      align-items: center;

      img {
        width: 44px;
        height: 44px;
      }

      p {
        margin-left: 8px;
        font-size: 28px;

        font-weight: 400;
        color: #1C1D21;
        line-height: 44px;
      }
    }

    .right {
      display: flex;
      align-items: center;

      p {
        font-size: 28px;
        margin-right: 8px;
        font-weight: bold;
        color: #005FE9;
        line-height: 44px;
      }

      img {
        width: 48px;
        height: 48px;
      }
    }
  }

  .pay-price {
    min-height: 200px;
    padding: 44px 32px;
    background: #F4F5FC;
    border-radius: 32px;
    margin-top: 32px;
    margin-bottom: 32px;

    .list-box {
      .item {
        display: flex;
        justify-content: space-between;
        height: 36px;
        line-height: 36px;
        margin-bottom: 40px;
        position: relative;

        &::before {
          content: '';
          position: absolute;
          width: 36px;
          height: 36px;
          background: url('~@/assets/images/stayPay/check-icon.png') no-repeat center center;
          background-size: 100%;
        }

        .title {
          font-size: 28px;
          color: #1C1D21;
          padding-left: 52px;
        }

        .cont {
          font-size: 28px;
          font-weight: bold;
          color: #005FE9;
        }
      }

      .item.is-active::before {
        background: url('~@/assets/images/stayPay/checked-icon.png') no-repeat center center;
        background-size: 100%;
      }

      .item:last-child {
        margin-bottom: 0;
      }
    }
  }

  .friend-tips {
    margin-top: 32px;
    font-size: 28px;
    color: #FF8500;
    line-height: 36px;
    padding-left: 52px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 44px;
      top: 0;
      bottom: 0;
      left: 0;
      background: url('~@/assets/images/stayPay/tips-icon.png') no-repeat center center;
      background-size: 100%;
    }
  }
}

:deep(.btn-box) {
  padding: 32px 0;

  .submit-btn.can-submit-btn {
    background: #005FE9;
    color: #FFFFFF;
  }
}

.msgs {
  margin-bottom: 300px;
  display: flex;
  align-items: center;
  height: 124px;
  background: #F4F5FC;
  border-radius: 32px;
  padding: 0 40px;

  img {
    width: 44px;
    height: 44px;
    margin-right: 16px;
  }

  :deep(.van-notice-bar) {
    height: 124px;
    background: #F4F5FC;
    border-radius: 32px;
    padding: 0 40px;

    .van-notice-bar__left-icon {
      width: 60px;

      &::before {
        display: none;
      }

      &::after {
        content: '';
        position: absolute;
        width: 44px;
        height: 44px;
        left: 0;
        top: 50%;
        margin-top: -22px;
        background: url('~@/assets/images/public/notice-icon.png') no-repeat center center;
        background-size: 100%;
      }
    }

    .van-notice-bar__content {
      font-size: 28px;
      font-weight: 400;
      color: #1C1D21;
    }
  }
}

.marquee {
  width: 100%;
  line-height: 40px;
  padding: 0 !important;
}

.coupon-box {
  min-height: 124px;
  padding: 18px 32px;
  background: #F4F5FC;
  border-radius: 32px;
  margin-top: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .coupon-left {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-icon {
      width: 44px;
      height: 44px;
      margin-right: 14px;
    }

    .coupon-name {
      font-size: 28px;
      font-family: Roboto-Medium, Roboto;
      font-weight: 500;
      color: #1C1D21;
      line-height: 44px;
      width: 380px;
    }

    .coupon-time {
      font-size: 24px;
      font-family: Roboto-Regular, Roboto;
      font-weight: 400;
      color: #999999;
      line-height: 44px;
    }
  }

  .coupon-right {
    display: flex;
    justify-content: space-between;
    align-items: center;

    span {
      font-size: 28px;
      font-family: Roboto-Bold, Roboto;
      font-weight: bold;
      color: #005FE9;
      line-height: 44px;
    }

    img {
      width: 48px;
      height: 48px;
    }
  }
}

// 复贷入口
.reloan-enter {
  width: 128px;
  height: 128px;
  position: fixed;
  right: 40px;
  top: 38%;

  img {
    width: 100%;
    height: 100%;
  }
}

.coupon-popup {
  width: 670px;
  background: transparent;

  .coupon-container {
    font-size: 0;

    .top {
      padding: 64px 56px 48px;
      background: #fff;

      .popup-title {
        font-size: 48px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #333333;
        line-height: 56px;
        text-align: center;
      }

      .msg {
        font-size: 24px;
        font-family: Roboto-Bold, Roboto;
        font-weight: bold;
        color: #4d4c4c;
        line-height: 56px;
        text-align: center;
      }

      .coupon-content {
        width: 100%;
        height: 234px;
        background: url('../../assets//images//stayPay/popup-coupon-bg.png') no-repeat;
        background-size: 100% 100%;
        padding: 24px 30px;
        margin-top: 48px;

        .coupon-amount-title {
          font-size: 32px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 38px;
        }

        .coupon-amount {
          margin-top: 12px;
          font-size: 72px;
          font-family: Roboto-Bold, Roboto;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 88px;
        }

        .expire-time {
          margin-top: 16px;
          font-size: 28px;
          font-family: Roboto-Medium, Roboto;
          font-weight: 500;
          color: rgba(255, 255, 255, 0.8);
          line-height: 32px;
        }
      }

      .check {
        margin-top: 30px;
        text-align: center;
        font-size: 28px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #005FE9;
        line-height: 32px;
      }
    }

    .bottom {
      width: 100%;
      height: 238px;
      background: url('../../assets/images/stayPay/popup-show-bottom.png') no-repeat;
      background-size: 100% 100%;
      position: relative;
      top: -2px;
      padding-top: 94px;

      .use-btn {
        margin: 0 auto;
        width: 542px;
        height: 96px;
        background: #1C1D21;
        border-radius: 32px;
        font-size: 36px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 96px;
        text-align: center;
      }
    }
  }
}
</style>
