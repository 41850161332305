<template>
  <div :class="{'is-delay':deferredRepayment}" class="top-box-area">
    <div class="top-box-area-header">
      <span class="title"><img class="back" @click="handleBack" v-if="isBack" src="@/assets/images/compoundLoan/back-icon-white.png" alt="">CashAgad</span>
      <span class="serivce" @click="openService()" >
        <span class="redDot-class" v-if="isRedDot"></span>
      </span>
    </div>
    <div class="money-box">
      <span class="title">{{ payType }}</span>
      <van-popover v-model="showPopover" :offset="[-5,0]" class="tips-box" placement="top" trigger="click">
        <div class="tips-cont">{{ $t('userCenter.formula') }}</div>
        <template #reference>
          <span class="tips" v-if="payTypeNum==2"></span>
        </template>
      </van-popover>
      <!-- <span class="price" @click="payDetail">PHP {{ Math.ceil(dataForm.borrowingPrice-cuponmoney) | toThousandFilter }}</span> -->
      <div @click="payDetail" class="price-box">
        <span class="price">PHP {{ Math.ceil(dataForm.borrowingPrice-cuponmoney) | toThousandFilter }}</span>
        <img src="~@/assets/images/public/arrow-right.png" alt="">
      </div>
      <div :class="{'is-overdue':dataForm.overdueDay}" class="use-date-box">
        <div>
          <p class="times">
            <span class="times-yanchang" v-if="payTypeNum==2">{{$t('userCenter.yanchang8tian')}} {{dataForm.postpone_timers}} {{$t('loan.day')}}</span> 
            <span>{{ deferredRepayment?$t('userCenter.deferredRepayment') : $t('userCenter.repayment') }} </span>{{ deferredRepayment ? ( dataForm.postpone_repay_time) : dataForm.repayTime }}</p>
            <!-- {{ deferredRepayment ? (dataForm.postpone_timers +'days'+ '('+dataForm.postpone_repay_time+')') : dataForm.repayTime }} -->
            
          <p v-show="dataForm.overdueDay>0" class="overdue-day">{{ dataForm.overdueDay }} {{ $t('baseInfo.days',{day:dataForm.overdueDay*1}) }} past due</p>
        </div>
      </div>
      <span class="show-code-icon">{{ dataForm.pay_period }}/2 issue</span>
    </div>
  </div>
</template>

<script>
import { playPay } from '@/api/axios/index'
export default {
  props: {
    cuponmoney:{
      type: Number,
      default: 0
    },
    isBack:{
      type: Boolean,
      default: false
    },

    payType: {
      type: String,
      default: ''
    },
    orderId: {
      type: [String, Number],
      default: ''
    },
    payTypeNum: {
      type: [String, Number],
      default: ''
    },
    dataForm: {
      type: Object,
      default: () => {
        return {
          borrowingPrice: '',
          borrowingDate: '',
          loanTerm: '',
          overdueDay: '', // 逾期天数
          repayTime: '', // 应还时间
          pay_period: '1',
          postpone_repay_time: '',
          postpone_timers: ''
        }
      }
    }
  },
  data() {
    return {
      showPopover: false,
      deferredRepayment: this.$route.query.pay_type ? this.$route.query.pay_type == 2 : this.payTypeNum == 2,
      isRedDot:false
    }
  },
  watch: {
    payTypeNum: {
      handler(n) {
        this.deferredRepayment = n == 2
      },
      immediate: true
    },
    orderId: {
      handler(n) {
        this.orderId = n
      },
      immediate: true
    }
  },
  computed: {
    setDate() {
     
      return this.dataForm.overdueDay ? this.$t('navbar.overdueDay', { day: this.dataForm.overdueDay }) : this.$t('navbar.repayTime', { time: this.dataForm.repayTime })
    }
    
  },
  created () {
    console.log(this.dataForm,'this.dataFormthis.dataForm');
    window.haveCustomerServiceMessage=(type)=>{
      this.redDot(type)
    }
  },
  methods: {
    // 点击返回
    handleBack(){
      this.$emit('onBack')
    },

    // 客服头像 是否展示红点
    redDot(type){
      console.log('客服红点1');
      this.isRedDot = type
    },

    async showCode() {
      const { data } = await playPay({ order_id: this.orderId, pay_type: 2, payment_type: 1 }) || {}
      this.barcode_url = data?.barcode_url || ''
      // this.showCodeVisible = true
      if (!data?.payment_name) return
    },
    payDetail() {
      console.log( this.deferredRepayment ,' this.deferredRepayment ');
      if (this.deferredRepayment) return
      this.$emit('repay_num')
      this.$router.push({
        path: '/payDetail',
        query: { order_id: this.orderId }
      })
    },

    openService() {
      this.$H5Object.goCustomServicePage()
    }
  }
}
</script>
<style lang="scss" scoped>
.back{
  width: 44px;
  height: 44px;
  margin-right: 8px;
}
.top-box-area{
  height: 500px;
  padding: 22px 40px;
  margin-top: 88px;
  background: url('~@/assets/images/public/top-bg-icon.png') no-repeat right 10px #005FE9FF;
  background-size: 482px 380px;
  .top-box-area-header{
    position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 88px;
      background: #005FE9;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding:0 40px;

 
    .title{
      font-size: 56px;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 84px;
    }
    .serivce{
      display: block;
      width: 44px;
      height: 44px;
      background: url('~@/assets/images/public/serivce-icon.png') no-repeat center center;
      background-size: 100%;
      position: relative;
      .redDot-class{
        display: inline-block;
        width: 16px;
        height: 16px;
        position: absolute;
        background: red;
        border-radius: 50%;
        top: 0;
        right: 0;
      }
    }
  }
}

.money-box{
  margin-top:186px ;
  height: 394px;
  // padding-top: 64px;
  padding-top: 42px;
  padding-left: 48px;
  margin-bottom: 32px;
  position: relative;
  background: url(~@/assets/images/stayPay/main-bg.png) no-repeat center center;
  background-size: 100%;
  .title{
    display: inline-block;
    font-size: 28px;
    font-weight: 400;
    color: #CCCCCC;
    margin-bottom: 30px;
    line-height: 44px;
  }
  .price{
    height: 88px;
    font-size: 72px;
    font-weight: bold;
    color: #FFFFFF;
    line-height: 88px;
    position: relative;
    // width: 100%;
    // display: table-cell;
    &::before{
      content:'';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 2px;
      background: #fff;
    }
    // &::after{
    //   content:'';
    //   position: absolute;
    //   width: 48px;
    //   height: 48px;
    //   background: url('~@/assets/images/public/arrow-right.png') no-repeat right center;
    //   background-size: 100%;
    //   top: 50%;
    //   margin-top: -24px;
    //   padding-right: 20px;
    // }
  }
  .date-box{
    display: flex;
    .date-item{
      flex: 1;
      .date-title{
        font-size: 24px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.8);
        padding-bottom: 18px;
      }
      .other-fm{
        font-family: Roboto-Regular, Roboto;
      }

      .date-cont{
        font-size: 32px;
        font-family: Roboto-Medium, Roboto;
        font-weight: 500;
        color: #FFFFFF;
      }
    }
    .other-style{
      float: right;
      flex: inherit;
    }
  }
  .times-yanchang{
    display: block;
    margin-bottom: 6px;
  }
  .use-date-box{
    height: 92px;
    font-weight: 400;
    color: #FFFFFF;
    margin-top: 48px;
    display: flex;
    align-items: center;
    .times{
      font-size: 28px;
      font-weight: bold;
      color: #FFFFFF;
      
      span{
        color: #CCCCCC;
        padding-right: 16px;
      }
    }
    .overdue-day{
      padding-top: 20px;
      font-size: 28px;
      color: #FF7245;
    }
  }
  .is-overdue{
    font-size: 28px;
    font-family: Roboto-Regular, Roboto;
    font-weight: 400;
    color: #FB5D58;
  }
  .show-code-icon{
    width: 132px;
    height: 64px;
    line-height: 64px;
    background: #F7B014;
    border-radius: 32px 0px 0px 32px;
    position: absolute;
    top: 50px;
    right: 0;
    font-size: 24px;
    font-weight: bold;
    color: #FFFFFF;
    padding-left: 16px;
  }
}
:deep(.barcode){
  width: 411px;
  height: 113px;
  margin: 0 auto;
}
.barcode-dialog{
  width: 80%;
  min-height: 300px;
  padding-top: 50px;
}
:deep(.van-dialog__footer){
  padding-top: 50px;
}

:deep(.van-button){
  font-size: 28px;
}
.is-delay .price::before,
.is-delay .price::after{
  display:none;
}
.tips{
  display: inline-block;
  width: 44px;
  height: 44px;
  margin-left: 10px;
  position: relative;
  &::after{
    content: '';
    position: absolute;
    width: 44px;
    height: 44px;
    top: 10px;
    background: url('~@/assets/images/stayPay/black-tips-icon.png') no-repeat center center;
    background-size: 100%;
  }
}
.is-delay .tips{
  display: inline-block;
}

.is-delay .price{
  display: block;
}

.is-delay .title{
  display: inline-block;
}

.tips-cont{
  padding: 24px;
}
.price-box{
  display: flex;
  align-items: center;
  img{
    width: 55px;
    height: 55px;
  }
}
</style>
<style>
.van-popover__content{
  background: url('~@/assets/images/stayPay/tips-bg-icon.png') no-repeat center center !important;
  background-size: 100%;
  border-radius: 25px;
}
.van-popover--light .van-popover__arrow {
  color: #dee1e8;
}
/* .van-popup{
  left: 40px!important;
  right:40px!important;
} */
.van-popover__arrow{
  left: 54.5%!important;
}

</style>
